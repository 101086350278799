import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IPasswordValidationConfiguration, IComplexityRules, IComplexityRulesValidationResult, IPasswordValidationSummary, IComplexityRulesRequest } from '../models';
import { TValidateCodeRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TValidateCodeRequestBody';
import { TValidateCodeResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TValidateCodeResponseBody';
import { config } from '../../App/models/config';

export class PasswordValidationStore extends StoreBase {
	static componentKey: 'passwordValidation' = 'passwordValidation';
	@observable.ref complexityRules: IComplexityRules | undefined = undefined;
	@observable password = '';
	@observable confirmPassword = '';

	@computed
	get configuration(): IPasswordValidationConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(PasswordValidationStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		const { appStore } = this.storeContext;
		const loadingKey = 'PasswordValidationStore.initialize';
		appStore.startLoading(loadingKey);
		this.complexityRules = await this.retrieveComplexityRules();
		appStore.stopLoading(loadingKey);
	}

	async retrieveComplexityRules() {
		const resetToken = this.resetToken;
		const { kurtosysApiStore } = this.storeContext;
		const overrideOptions = {
			body: {
				passwordRequestId: resetToken,
			},
		};
		const rules = await kurtosysApiStore.passwordRequirements.execute(overrideOptions);

		// Remove the enforcePreviousPasswordCheck property being returned from the endpoint
		// as we do not need to do anything with it currently. It is being used in the portals.
		if (typeof (rules as any).enforcePreviousPasswordCheck !== 'undefined') {
			delete (rules as any).enforcePreviousPasswordCheck;
		}
		return rules;
	}

	@computed
	get resetToken() {
		const { setPasswordStore } = this.storeContext;
		return setPasswordStore.resetToken;
	}



	maxLength = (max: number) => (value: string) => value.length <= max;

	minLength = (min: number) => (value: string) => value.length >= min;

	minNumbers = (min: number) => (value: string) => value.replace(/[^\d]/gi, '').length >= min;

	minLetters = (min: number) => (value: string) => value.replace(/[^a-zA-Z]/gi, '').length >= min;

	minPunctuation = (min: number) => (value: string) => {
		const validPunctuation = '!"#$%&\'()* +,-./:;<=>?@[]^_`{|}~';
		return value.split('').filter(char => validPunctuation.includes(char)).length >= min;
	}

	requireUppercaseAndLowercase = (enabled: boolean) => (value: string) => {
		return !enabled || (/[A-Z]/.test(value) && /[a-z]/.test(value));
	}

	limitCharacterRepetition = (enabled: boolean, max: number = 2) => (value: string) => {
		if (!enabled) {
			return true;
		}
		let previousLetter = '';
		let count = 0;
		for (const char of value) {
			if (char === previousLetter) {
				count++;
			}
			else {
				previousLetter = char;
				count = 1;
			}
			if (count > max) {
				return false;
			}
		}
		return true;
	}

	get validators() {
		return {
			minLength: this.minLength,
			maxLength: this.maxLength,
			minLetters: this.minLetters,
			minPunctuation: this.minPunctuation,
			minNumbers: this.minNumbers,
			requireUppercaseAndLowercase: this.requireUppercaseAndLowercase,
			limitCharacterRepetition: this.limitCharacterRepetition,
		};
	}

	validatePassword = (password: string): IComplexityRulesValidationResult => {
		const summary: IComplexityRulesValidationResult = {};
		if (this.complexityRules) {
			for (const rule in this.complexityRules) {
				if ((this.validators as any)[rule]) {
					summary[rule] = (this.validators as any)[rule]((this.complexityRules as any)[rule])(password);
				}
			}
		}
		return summary;
	}

	@computed
	get messages() {
		if (this.complexityRules) {
			return {
				minLength: `Minimum ${ this.complexityRules.minLength } characters`,
				maxLength: `Maximum ${ this.complexityRules.maxLength } characters`,
				minLetters: `Must contain ${ this.complexityRules.minLetters } letter${
					this.complexityRules.minLetters === 1 ? '' : 's'
					}`,
				minPunctuation: `Must contain ${ this.complexityRules.minPunctuation } special character${
					this.complexityRules.minPunctuation === 1 ? '' : 's'
					}`,
				minNumbers: `Must contain ${ this.complexityRules.minNumbers } number${
					this.complexityRules.minNumbers === 1 ? '' : 's'
					}`,
				requireUppercaseAndLowercase: `Must contain upper and lower case`,
				limitCharacterRepetition: `Should not repeat the same character 3 times consecutively`,
				match: `Passwords must match`,
			};
		}
	}

	@computed
	get passwordCriteria() {
		const messages: IPasswordValidationSummary[] = [];
		const summary = this.validatePassword(this.password);
		if (this.complexityRules) {
			for (const rule in this.complexityRules) {
				if ((this.complexityRules as any)[rule]) {
					messages.push({
						rule,
						valid: !!summary[rule],
						message: (this.messages && (this.messages as any)[rule]) || '',
					});
				}
			}
		}

		messages.push({
			rule: 'match',
			valid: this.password !== '' && this.password === this.confirmPassword,
			message: 'Passwords must match',
		});
		return messages;
	}

	@computed
	get isValid() {
		return this.passwordCriteria.every(m => m.valid);
	}

	@computed
	get title(): string {
		return (this.configuration && this.configuration.title) || 'Password criteria';
	}
}

