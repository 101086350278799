import React, { Component } from 'react';
import {
	ISetPasswordProps,
	ISetPasswordState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import PasswordValidation from '../PasswordValidation';
import Wrapper from './styledComponents/Wrapper';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import IconInput from '../App/styledComponents/IconInput';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import InfoMessage from '../App/styledComponents/InfoMessage';
import Heading from './styledComponents/Heading';

@inject('appStore', 'setPasswordStore', 'messageStore')
@observer
export class SetPassword extends Component<ISetPasswordProps, ISetPasswordState> {
	static configurationKey: 'setPassword' = 'setPassword';
	static styleKey: 'setPassword' = 'setPassword';
	constructor(props: ISetPasswordProps) {
		super(props);
	}
	render() {
		const { className, setPasswordStore, messageStore } = this.props;

		if (!setPasswordStore || !messageStore) {
			return null;
		}
		const {
			heading,
			text,
			password,
			confirmPassword,
			passwordPlaceholderText,
			confirmPasswordPlaceholderText,
			setPassword,
			setConfirmPassword,
			handleUpdateClick,
			updatePasswordText,
			isValid,
		} = setPasswordStore;
		return (
			<Wrapper className={ className }>
				{ heading && <Heading data-qa-ref="setPasswordHeading">
					{ heading }
				</Heading> }
				<InfoMessage data-qa-ref="setPasswordInfo1">
					{ messageStore.infoText }
				</InfoMessage>
				{ text && <InfoMessage data-qa-ref="setPasswordInfo1">
					{ text }
				</InfoMessage> }
				<IconInput
					name="password"
					placeholder={ passwordPlaceholderText }
					type="password"
					onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value) }
					value={ password }
					iconProps={ { asset: 'base.iconInput.fingerPrint' } }
					hasError={ messageStore.hasErrorText }
					data-qa-ref="passwordInput"
				/>
				<IconInput
					name="confirmPassword"
					placeholder={ confirmPasswordPlaceholderText }
					type="password"
					onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value) }
					value={ confirmPassword }
					iconProps={ { asset: 'base.iconInput.fingerPrint' } }
					hasError={ messageStore.hasErrorText }
					data-qa-ref="confirmPasswordInput"
				/>
				<PasswordValidation />
				<ErrorMessage data-qa-ref="setPasswordError">
					{ messageStore.errorText }
				</ErrorMessage>
				<ButtonRaised disabled={ !isValid } onClick={ handleUpdateClick } data-qa-ref="requestForgotPasswordButton">
					{ updatePasswordText }
				</ButtonRaised>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SetPassword, 'setPassword');

