import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IMfaSetupPushConfiguration } from '../models';
import { TMfaEnrollAuthRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TMfaEnrollAuthRequestBody';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { TMfaEnrollRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TMfaEnrollRequestBody';
import { MfaMethods, MfaStep } from '../../MfaSetup/stores/MfaSetupStore';

export class MfaSetupPushStore extends StoreBase {
	static componentKey: 'mfaSetupPush' = 'mfaSetupPush';
	@observable loading: boolean = true;
	@observable loadingAuth: boolean = false;
	@observable showSkip: boolean = true;
	@observable duoBarcode: string = '';
	@computed
	get configuration(): IMfaSetupPushConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(MfaSetupPushStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		const { kurtosysApiStore } = this.storeContext;
		const overrideOptions: Partial<IRequestOptions<TMfaEnrollRequestBody>> = {
			body: {
				type: MfaMethods.push,
			},
		};
		try {
			const response = await kurtosysApiStore.mfaEnroll.execute(overrideOptions);
			if (typeof response === 'object') {
				this.duoBarcode = response.barcode;
			}
		}
		catch (ex) {
			const { messageStore } = this.storeContext;
			messageStore.setErrorText(this.barcodeRequestFailText);
		}
		this.loading = false;
	}

	@computed
	get barcodeRequestFailText() {
		return (this.configuration && this.configuration.barcodeRequestFailText) || 'There was an error retrieving the barcode.';
	}

	@computed
	get accountSetupFailText() {
		return (this.configuration && this.configuration.accountSetupFailText) || 'There was an error setting up your account.';
	}

	@computed
	get headingText() {
		return (this.configuration && this.configuration.headingText) || 'Setup Duo Mobile Two Factor Authentication';
	}

	@computed
	get prefixText() {
		return (this.configuration && this.configuration.prefixText) || 'Scan the barcode below with the Duo Mobile application on your smartphone or tablet.';
	}

	@computed
	get suffixText() {
		return (this.configuration && this.configuration.suffixText) || 'We\'ll send you a login code to confirm that this is your number.';
	}

	@computed
	get skipDescriptionText() {
		return (this.configuration && this.configuration.skipDescriptionText) || 'Don\'t have an iPhone or Android device?';
	}

	@computed
	get skipLinkText() {
		return (this.configuration && this.configuration.skipLinkText) || 'Skip this step';
	}

	@computed
	get nextButtonText(): string {
		return (this.configuration && this.configuration.nextButtonText) || 'Next';
	}

	@computed
	get finishButtonText(): string {
		return (this.configuration && this.configuration.finishButtonText) || 'Finish';
	}

	@computed
	get buttonText(): string {
		const { mfaSetupStore } = this.storeContext;
		return mfaSetupStore.isCallEnabled || mfaSetupStore.isTextEnabled ? this.nextButtonText : this.finishButtonText;
	}

	@action
	skipAction = () => {
		const { mfaSetupStore } = this.storeContext;
		mfaSetupStore.setStep(MfaStep.backupSetup);
	}

	@action nextStep = async () => {
		this.loadingAuth = true;
		const { kurtosysApiStore, mfaSetupStore } = this.storeContext;
		const overrideOptions: Partial<IRequestOptions<TMfaEnrollAuthRequestBody>> = {
			body: {
				type: MfaMethods.push,
			},
		};
		try {
			const response = await kurtosysApiStore.mfaEnrollAuth.callApi(overrideOptions);
			if (response.ok) {
				mfaSetupStore.setStep(MfaStep.backupSetup);
				return;
			}
		}
		catch (ex) {

		}
		const { messageStore } = this.storeContext;
		messageStore.setErrorText(this.accountSetupFailText);
	}
}