import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ILoginCodeConfiguration } from '../models';
import { config } from '../../App/models/config';

export class LoginCodeStore extends StoreBase {
	static componentKey: 'loginCode' = 'loginCode';
	@observable public confirmationCode: string | undefined = '';

	@computed
	get configuration(): ILoginCodeConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(LoginCodeStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get titleText(): string {
		return (this.configuration && this.configuration.titleText) || 'Login Code';
	}

	@computed
	get placeholderText(): string {
		return (this.configuration && this.configuration.placeholderText) || 'Code';
	}

	@computed
	get confirmButtonText(): string {
		return (this.configuration && this.configuration.confirmButtonText) || 'Confirm';
	}

	@computed
	get resendButtonText(): string {
		return (this.configuration && this.configuration.resendButtonText) || 'Resend Code';
	}

	@computed
	get textVerifyProcessing() {
		const { appStore } = this.storeContext;
		return appStore.currentMfaStep === config.mfaSteps.DUO_BACKUP_TEXT_CONFIRM && appStore.loading;
	}

	@action
	public setConfirmationCode(input: string | undefined) {
		this.confirmationCode = input;
	}

	@action
	public confirmTextCode = async () => {
		const { loginStrategySelectorStore } = this.storeContext;
		await loginStrategySelectorStore.onAuthMethodSelect({ type: 'PASSCODE' });
	}

	@action
	public resendCode = () => {
		const { loginStrategySelectorStore } = this.storeContext;
		this.setConfirmationCode(undefined);
		loginStrategySelectorStore.onAuthMethodSelect({ type: 'TEXT' });
	}
}