import React, { Component } from 'react';
import {
	IMfaSetupPushProps,
	IMfaSetupPushState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Heading from '@kurtosys/ksys-app-components/dist/components/base/Heading';
import Wrapper from './styledComponents/Wrapper';
import InfoMessage from '../App/styledComponents/InfoMessage';
import DuoBarcode from './styledComponents/DuoBarcode';
import DuoBarcodeLoadingContainer from './styledComponents/DuoBarcodeLoadingContainer';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import LoadingIndicator from '@kurtosys/ksys-app-components/dist/components/base/LoadingIndicator';
@inject('appStore', 'mfaSetupPushStore', 'messageStore')
@observer
export class MfaSetupPush extends Component<IMfaSetupPushProps, IMfaSetupPushState> {
	static configurationKey: 'mfaSetupPush' = 'mfaSetupPush';
	static styleKey: 'mfaSetupPush' = 'mfaSetupPush';
	constructor(props: IMfaSetupPushProps) {
		super(props);
	}
	render() {
		const { className, mfaSetupPushStore, messageStore } = this.props;

		if (!mfaSetupPushStore || !messageStore) {
			return null;
		}

		const {
			loading,
			showSkip,
			buttonText,
			duoBarcode,
			skipAction,
			loadingAuth,
			nextStep,
			headingText,
			prefixText,
			suffixText,
			skipDescriptionText,
			skipLinkText,
		} = mfaSetupPushStore;
		return (
			<Wrapper className={ className }>

				<Heading level={ 1 } title={ headingText } />

				<InfoMessage>
					{ prefixText }
				</InfoMessage>

				{ showSkip && (
					<InfoMessage>
						{ skipDescriptionText } <a onClick={ skipAction }>{ skipLinkText }</a>
					</InfoMessage>
				) }
				{ loading ? (
					<DuoBarcodeLoadingContainer>
						<LoadingIndicator />
					</DuoBarcodeLoadingContainer>
				) : (
						<DuoBarcode src={ duoBarcode } />
					) }

				<ErrorMessage>
					{ messageStore.errorText }
				</ErrorMessage>

				<InfoMessage>
					<Translate placeholders={ { buttonText } }>
						{ suffixText }
					</Translate>
				</InfoMessage>
				{ loadingAuth ? (
					<LoadingIndicator />
				) : (
						<ButtonRaised onClick={ nextStep }>{ buttonText }</ButtonRaised>
					) }

			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(MfaSetupPush, 'mfaSetupPush');

