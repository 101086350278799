import React, { Component, Suspense, lazy } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import { config } from './models/config';
import { LoginStrategySelector } from '../LoginStrategySelector/LoginStrategySelector';
import LogoWrapper from './styledComponents/LogoWrapper';
import Logo from './styledComponents/Logo';
import UserLogin from '../UserLogin';
import ForgotPassword from '../ForgotPassword';
import ForgotUsername from '../ForgotUsername';
import SetPassword from '../SetPassword';
import Feedback from '../Feedback';
import UserLogin2Fa from '../UserLogin2Fa';
import SetupAssurances from '../SetupAssurances';
import MfaSetup from '../MfaSetup';
@inject('appStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}
	render() {
		const { className, appStore, selector, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders, currentStep, loading } = appStore;
		if (!isBootstrapped || loading) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}
		const { theme, show, libraryComponentsConfiguration, getTranslateFunction, assets, assetCacheOptions, assetRegisters } = appStore;

		if (!show || !currentStep) {
			return null;
		}

		const translate = getTranslateFunction();
		const { loginSteps } = config;
		const {
			ACCOUNT_LOCKED,
			CODE_INVALID,
			ERROR,
			USER_SUCCESS,
			LOGIN_STRATEGY_SELECTOR,
			USER_LOGIN,
			RESET_PASSWORD,
			FORGOT_USERNAME,
			SET_PASSWORD,
			USER_LOGIN_2FA,
			SETUP_ASSURANCES,
			MFA,
		} = loginSteps;
		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters }>
				<Wrapper className={ className }>
					<LogoWrapper><Logo asset={ 'base.image.logo' } data-qa-ref="titleLogoImage" /></LogoWrapper>
					{ currentStep === LOGIN_STRATEGY_SELECTOR && <LoginStrategySelector /> }
					{ currentStep === USER_LOGIN && <UserLogin /> }
					{ currentStep === RESET_PASSWORD && <ForgotPassword /> }
					{ currentStep === FORGOT_USERNAME && <ForgotUsername /> }
					{ currentStep === SET_PASSWORD && <SetPassword /> }
					{ currentStep === USER_LOGIN_2FA && <UserLogin2Fa /> }
					{ currentStep === SETUP_ASSURANCES && <SetupAssurances /> }
					{ currentStep === MFA && <MfaSetup /> }
					{ [ACCOUNT_LOCKED, CODE_INVALID, ERROR, USER_SUCCESS].includes(currentStep) && <Feedback /> }
				</Wrapper>
			</Application>
		);
	}
}

export default InjectedStyledComponent(App, 'app');
