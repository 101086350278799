import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IMfaSetupBackupConfiguration } from '../models';
import { MfaStep, MfaMethods } from '../../MfaSetup/stores/MfaSetupStore';
import { config } from '../../App/models/config';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { TMfaEnrollAuthRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TMfaEnrollAuthRequestBody';
import { TMfaEnrollRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TMfaEnrollRequestBody';
import { IPhoneNumberProps } from '../../PhoneNumber/models';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { getCountryCodes } from '../../shared/countryCodes';
export class MfaSetupBackupStore extends StoreBase {
	static componentKey: 'mfaSetupBackup' = 'mfaSetupBackup';
	@observable loading: boolean = true;
	@observable loadingAuth: boolean = false;
	@observable showSkip: boolean = true;
	@observable showCode: boolean = false;
	@observable phoneNumber: string = '';
	@observable confirmedCall: boolean = false;
	@observable confirmedText: boolean = false;
	@observable countryCode: string = '+44';
	@observable hasCalledOnce: boolean = false;
	@observable hasSentCodeOnce: boolean = false;
	@observable confirmationCode: string = '';
	@computed
	get configuration(): IMfaSetupBackupConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(MfaSetupBackupStore.componentKey);
		}
	}

	@action
	setConfirmationCode = (value: string) => {
		this.confirmationCode = value;
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get accountSetupFailText() {
		return (this.configuration && this.configuration.accountSetupFailText) || 'There was an error setting up your account.';
	}

	@computed
	get headingText() {
		return (this.configuration && this.configuration.headingText) || 'Setup Duo Mobile Two Factor Authentication';
	}

	@computed
	get prefixText() {
		return (this.configuration && this.configuration.prefixText) || 'Enter the phone number you will use to verify your identity.';
	}

	@computed
	get suffixText() {
		return (this.configuration && this.configuration.suffixText) || 'We\'ll send you a login code to confirm that this is your number. Select the method from which you want to receive your code.';
	}

	@computed
	get skipDescriptionText() {
		return (this.configuration && this.configuration.skipDescriptionText) || ' if you do not want to activate this security feature.';
	}

	@computed
	get skipLinkText() {
		return (this.configuration && this.configuration.skipLinkText) || 'Skip this step';
	}

	@computed
	get nextButtonText(): string {
		return (this.configuration && this.configuration.nextButtonText) || 'Next';
	}

	@computed
	get finishButtonText(): string {
		return (this.configuration && this.configuration.finishButtonText) || 'Finish';
	}

	@computed
	get buttonText(): string {
		const { mfaSetupStore } = this.storeContext;
		return mfaSetupStore.isCallEnabled || mfaSetupStore.isTextEnabled ? this.nextButtonText : this.finishButtonText;
	}

	@computed
	get callEnabled(): boolean {
		const { mfaSetupStore } = this.storeContext;
		return mfaSetupStore.isCallEnabled;
	}

	@computed
	get textEnabled(): boolean {
		const { mfaSetupStore } = this.storeContext;
		return mfaSetupStore.isTextEnabled;
	}

	@action
	skipAction = () => {
		const { appStore } = this.storeContext;
		appStore.setStep(config.loginSteps.USER_SUCCESS);
	}

	@action finishMfaSetup = async () => {
		if (this.canProceed) {
			const { appStore } = this.storeContext;
			appStore.setStep(config.loginSteps.USER_SUCCESS);
		}
	}

	@computed
	get canProceed() {
		if (this.callEnabled || this.textEnabled) {
			return this.confirmedText || this.confirmedCall;
		}
		return false;
	}

	getPhoneNumber = () => {
		return this.countryCode + this.phoneNumber;
	}

	@computed
	get invalidPhoneNumberText() {
		return (this.configuration && this.configuration.invalidPhoneNumberText) || 'Please enter a valid telephone number.';
	}

	validatePhoneNumber = () => {
		const { messageStore } = this.storeContext;
		const valid = (this.phoneNumber && this.phoneNumber.match(/^\d+$/)) || false;
		if (!valid) {
			messageStore.setErrorText(this.invalidPhoneNumberText);
			return false;
		}
		return true;
	}

	@action
	verifyBackup = async (method: MfaMethods): Promise<boolean> => {
		const { messageStore, kurtosysApiStore } = this.storeContext;
		if (!this.validatePhoneNumber()) {
			return false;
		}

		const phoneNumber = this.getPhoneNumber();
		const overrideOptions: Partial<IRequestOptions<TMfaEnrollRequestBody>> = {
			body: {
				type: method,
				phone: phoneNumber,
			},
		};
		try {
			const response = await kurtosysApiStore.mfaEnroll.callApi(overrideOptions);
			if (response.ok) {
				const authOverrideOptions: Partial<IRequestOptions<TMfaEnrollAuthRequestBody>> = {
					body: {
						type: method,
					},
				};
				const authResponse = await kurtosysApiStore.mfaEnrollAuth.callApi(authOverrideOptions);
				if (authResponse.status === 200) {
					this.loadingAuth = false;
					if (method === MfaMethods.call) {
						this.hasCalledOnce = true;
					}
					if (method === MfaMethods.text) {
						this.hasSentCodeOnce = true;
					}
					messageStore.clearError();
					return true;
				}
			}
		}
		catch (ex) {
			// The messageStores errorText is the same and will be set outside of this catch
		}
		messageStore.setErrorText(this.accountSetupFailText);
		return false;
	}

	@action
	verifyCall = async () => {
		this.loadingAuth = true;
		this.confirmedCall = false;
		const response = await this.verifyBackup(MfaMethods.call);
		this.confirmedCall = response;
		this.loadingAuth = false;
	}

	@action
	verifyText = async () => {
		this.loadingAuth = true;
		this.confirmedText = false;
		const response = await this.verifyBackup(MfaMethods.text);
		this.showCode = response;
		this.loadingAuth = false;

	}

	@action confirmTextCode = async () => {
		const { messageStore, kurtosysApiStore } = this.storeContext;
		if (!this.validatePhoneNumber()) {
			return false;
		}

		this.loadingAuth = true;
		const authOverrideOptions: Partial<IRequestOptions<TMfaEnrollAuthRequestBody>> = {
			body: {
				type: MfaMethods.text,
				passcode: this.confirmationCode,
			},
		};
		const response = await kurtosysApiStore.mfaEnrollAuth.callApi(authOverrideOptions);

		if (response.status === 200) {
			this.loadingAuth = false;
			this.confirmedText = true;
			this.showCode = false;
			this.confirmationCode = '';
			return;
		}
		messageStore.setErrorText(this.accountSetupFailText);
		this.loadingAuth = false;
	}

	@computed
	get phoneNumberPlaceholder(): string {
		return (this.configuration && this.configuration.phoneNumberPlaceholder) || 'Phone number';
	}

	@computed
	get confirmedSuffixText(): string {
		return (this.configuration && this.configuration.confirmedSuffixText) || 'CONFIRMED';
	}

	@computed
	get callButtonText() {
		const phoneNumber = this.getPhoneNumber();
		if (this.confirmedCall) {
			return this.confirmedPhoneNumberText(phoneNumber);
		}
		return (this.configuration && this.configuration.callButtonText) || 'CALL ME';
	}

	@computed
	get textButtonText() {
		const phoneNumber = this.getPhoneNumber();
		if (this.confirmedText) {
			return this.confirmedPhoneNumberText(phoneNumber);
		}
		if (this.hasSentCodeOnce) {
			return (this.configuration && this.configuration.resendCodeText) || 'RESEND CODE';
		}
		return (this.configuration && this.configuration.textButtonText) || 'TEXT ME';
	}

	obfuscatePhone(phoneNumber: string) {
		if (!phoneNumber) {
			return '';
		}

		const length = phoneNumber.length;
		return phoneNumber.substr(length - 3, 3).padStart(length, '*');
	}

	confirmedPhoneNumberText(phoneNumber: string) {
		return this.obfuscatePhone(phoneNumber) + ' ' + this.confirmedSuffixText;
	}

	countryCodes: IDropdownItem[] = getCountryCodes();

	@computed
	get phoneNumberProps(): IPhoneNumberProps {
		return {
			dropdownProps: {
				selectedItem: this.countryCodes.find(c => c.value === this.countryCode) || this.countryCodes[0],
				items: this.countryCodes,
				onChange: (item: IDropdownItem) => {
					this.countryCode = item.value;
				},
			},
			inputProps: {
				value: this.phoneNumber,
				onChange: (event) => {
					this.phoneNumber = (event.target as any).value;
				},
				placeholder: this.phoneNumberPlaceholder,
			},
		};
	}
}