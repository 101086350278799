import React, { Component } from 'react';
import {
	IForgotPasswordProps,
	IForgotPasswordState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import IconInput from '../App/styledComponents/IconInput';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import SuccessMessage from '../App/styledComponents/SuccessMessage';

@inject('appStore', 'forgotPasswordStore', 'messageStore', 'userLoginStore')
@observer
export class ForgotPassword extends Component<IForgotPasswordProps, IForgotPasswordState> {
	static configurationKey: 'forgotPassword' = 'forgotPassword';
	static styleKey: 'forgotPassword' = 'forgotPassword';
	constructor(props: IForgotPasswordProps) {
		super(props);
	}
	render() {
		const { className, forgotPasswordStore, messageStore, userLoginStore } = this.props;

		if (!forgotPasswordStore || !messageStore || !userLoginStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<ButtonFlat onClick={ forgotPasswordStore.backToUserLogin } iconProps={ { asset: 'base.icons.backArrow' } } data-qa-ref="forgotPasswordBackButton" iconPosition={ 'left' }>
					{ forgotPasswordStore.backButtonText }
				</ButtonFlat>
				<IconInput
					name="username"
					placeholder={ forgotPasswordStore.placeholderText }
					onKeyDown={ forgotPasswordStore.onSubmitRequestPasswordReset }
					autoFocus
					onChange={ (event: React.ChangeEvent<HTMLInputElement>) => forgotPasswordStore.updateUsername(event.target.value) }
					value={ userLoginStore.username }
					iconProps={ { asset: 'base.iconInput.personOutline' } }
					hasError={ messageStore.hasErrorText }
					data-qa-ref="forgotPasswordInput"
				/>
				<ErrorMessage data-qa-ref="forgotPasswordError" >
					{ messageStore.errorText }
				</ErrorMessage>
				<SuccessMessage data-qa-ref="forgotPasswordInfo">
					{ messageStore.infoText }
				</SuccessMessage>
				<ButtonRaised onClick={ forgotPasswordStore.onSubmitRequestPasswordReset } data-qa-ref="requestForgotPasswordButton">
					{ forgotPasswordStore.forgotPasswordButtonText }
				</ButtonRaised>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(ForgotPassword, 'forgotPassword');

