import React, { Component } from 'react';
import {
	IUserLoginProps,
	IUserLoginState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ForgotLinksWrapper from './styledComponents/ForgotLinksWrapper';
import RegistrationLink from './styledComponents/RegistrationLink';
import ButtonRaised from '../App/styledComponents/ButtonRaised';
import ButtonFlat from '../App/styledComponents/ButtonFlat';
import ButtonText from '../App/styledComponents/ButtonText';
import IconInput from '../App/styledComponents/IconInput';
import ErrorMessage from '../App/styledComponents/ErrorMessage';
import { Assurance } from '../Assurance/Assurance';
import { utils } from '@kurtosys/ksys-app-template';

@inject('appStore', 'userLoginStore', 'messageStore', 'assuranceStore', 'loginStrategySelectorStore')
@observer
export class UserLogin extends Component<IUserLoginProps, IUserLoginState> {
	static configurationKey: 'userLogin' = 'userLogin';
	static styleKey: 'userLogin' = 'userLogin';
	constructor(props: IUserLoginProps) {
		super(props);
	}
	render() {
		const { className, userLoginStore, appStore, messageStore, assuranceStore, loginStrategySelectorStore } = this.props;

		if (!userLoginStore ||
			!appStore ||
			!messageStore ||
			!assuranceStore ||
			!loginStrategySelectorStore) {
			return null;
		}

		const { hasLoginStrategies } = loginStrategySelectorStore;
		return (
			<Wrapper className={ className }>
				{ hasLoginStrategies &&
					<ButtonFlat onClick={ appStore.clear } data-qa-ref="loginBackButton" iconProps={ { asset: 'base.icons.backArrow' } } iconPosition={ 'left' }>
						{ userLoginStore.backButtonText }
					</ButtonFlat>
				}
				<form
					spellCheck={ false }
					autoSave="false"
					onSubmit={ (e) => {
						e.preventDefault();
						return false;
					} }
				>
					<IconInput
						name="username"
						placeholder={ userLoginStore.usernamePlaceholderText }
						autoFocus
						onChange={ (event: React.ChangeEvent<HTMLInputElement>) => userLoginStore.updateUsername(event.target.value) }
						value={ userLoginStore.username }
						onBlur={ userLoginStore.checkUsernameForAssurance }
						iconProps={ { asset: 'base.iconInput.personOutline' } }
						hasError={ messageStore.hasErrorText }
						onKeyDown={ () => {
							userLoginStore.checkUsernameForAssurance();
							userLoginStore.validateUserLogin();
						} }
						data-qa-ref="usernameInput"
					/>
					{ assuranceStore.showAssuranceBox && (
						<Assurance { ...assuranceStore.assuranceProps } />
					) }
					<IconInput
						name="password"
						placeholder={ userLoginStore.passwordPlaceholderText }
						type="password"
						onChange={ (event: React.ChangeEvent<HTMLInputElement>) => userLoginStore.updatePassword(event.target.value) }
						value={ userLoginStore.password }
						iconProps={ { asset: 'base.iconInput.fingerPrint' } }
						hasError={ messageStore.hasErrorText }
						onKeyDown={ userLoginStore.validateUserLogin }
						data-qa-ref="passwordInput"
					/>
				</form>
				<ErrorMessage data-qa-ref="loginErrorMessage" >
					{ messageStore.errorText }
				</ErrorMessage>
				<ButtonRaised onClick={ userLoginStore.validateUserLogin } data-qa-ref="loginButton">
					{ userLoginStore.loginButtonText }
				</ButtonRaised>
				<ForgotLinksWrapper>
					{ appStore.isActionEnabled('forgot-username') && (
						<ButtonText
							onClick={ userLoginStore.onForgotUsername }
							data-qa-ref="forgotUsernameButton"
						>
							{ userLoginStore.forgotUsernameButtonText }
						</ButtonText>
					) }
					{ appStore.isActionEnabled('forgot-password') && (
						<ButtonText
							onClick={ userLoginStore.onForgotPassword }
							data-qa-ref="forgotPasswordButton"
						>
							{ userLoginStore.forgotPasswordButtonText }
						</ButtonText>
					) }
				</ForgotLinksWrapper>
				{
					!utils.typeChecks.isNullOrEmpty(userLoginStore.registrationText) && (
						<RegistrationLink href={ userLoginStore.registrationUrl }>
							{ userLoginStore.registrationText }
						</RegistrationLink>
					)
				}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(UserLogin, 'userLogin');

