import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		app: {
			children: {
				wrapper: {
					position: 'absolute',
					display: 'flex',
					justifyContent: 'center',
					align: {
						items: 'center',
					},
					flex: {
						direction: 'column',
					},
					width: '100%',
					height: '100%',
					background: {
						color: 'white',
					},
				},
				logoWrapper: {
					width: '180px',
					margin: {
						_value: '20px 0px',
					},
					padding: {
						_value: '16px 18px 12px',
					},
					background: {
						color: '#1a80ff',
					},
				},
			},
		},
		assurance: {
			children: {
				wrapper: {
					display: 'flex',
					justifyContent: 'space-between',
					position: 'relative',
					width: '340px',
					max: {
						width: '100%',
					},
					height: '78px',
					margin: { _value: '10px auto' },
					border: {
						_value: '1px solid #D6D9E1',
						radius: '0',
					},
					flex: {
						shrink: '0',
					},
				},
				iconWrapper: {
					width: '44px',
					display: 'flex',
					height: '100%',
					justifyContent: 'center',
					align: { items: 'center' },
				},
				iconComponent: {
					height: '20px',
				},
				textWrapper: {
					display: 'flex',
					flex: { grow: '1' },
					height: '100%',
					justifyContent: 'center',
					align: { items: 'center' },
					font: {
						size: '13px',
						family: '"DM Sans", sans-serif',
						weight: '400',
					},
					color: '#3a4051',
				},
				imageComponent: {
					width: '64px',
					height: '64px',
					display: 'block',
					margin: {
						_value: '5px',
						left: '1em',
					},
					border: {
						_value: '1px solid #D6D9E1',
						radius: '0',
					},
				},
			},
		},
		setPassword: {
			children: {
				wrapper: {
					display: 'flex',
					flex: {
						direction: 'column',
					},
				},
				heading: {
					color: '#d6d9e1',
					font: {
						size: '15px',
						weight: 'bold',
					},
					max: {
						width: '65%',
					},
					padding: {
						right: '0px',
					},
					text: {
						align: 'center',
					},
					margin: {
						_value: '0px auto 10px',
					},
				},
			},
		},
		passwordValidation: {
			children: {
				title: {
					color: '#FFFFFF',
					margin: {
						_value: '0 0.25em 0.5em',
					},
				},
				rule: {
					color: '#C6C6C6',
					display: 'flex',
				},
				ruleIcon: {
					margin: {
						right: '0.5em',
					},
					transform: 'transform: scale(0.7)',
				},
				wrapper: {
					background: {
						color: '#424242',
					},
					padding: {
						_value: '1em 1.5em',
					},
					border: {
						radius: '0px',
					},
					text: {
						align: 'left',
					},
					breakpoints: [
						{
							min: '900px',
							style: {
								position: 'absolute',
								left: 'calc(50% + 180px)',
								margin: {
									top: '40px',
								},
							},
						},
					],
				},

			},
		},
		userLogin: {
			children: {
				forgotLinksWrapper: {
					display: 'flex',
					text: {
						align: 'center',
						decoration: 'underline',
					},
					color: '#9599a7',
					justifyContent: 'center',
				},
			},
		},
		feedback: {
			children: {
				wrapper: {
					min: {
						width: '300px',
					},
				},
				iconWrapper: {
					text: {
						align: 'center',
					},
				},
			},
		},
		setupAssurances: {
			children: {
				wrapper: {
					text: {
						align: 'center',
					},
				},
				heading: {
					color: '#d6d9e1',
					font: {
						size: '15px',
						weight: 'bold',
					},
					max: {
						width: '65%',
					},
					padding: {
						right: '0px',
					},
					text: {
						align: 'center',
					},
					margin: {
						_value: '0px auto 10px',
					},
				},
			},
		},
		mfaSetupPush: {
			text: {
				align: 'center',
			},
		},
		mfaSetupBackup: {
			children: {
				loadingWrapper: {
					text: {
						align: 'center',
					},
				},
			},
		},
		phoneNumber: {
			children: {
				wrapper: {
					display: 'flex',
					flex: {
						wrap: 'wrap',
						direction: 'row',
					},
					justifyContent: 'center',
					padding: {
						top: '10px',
						bottom: '10px',
					},
				},
			},
		},
	},
	theme: {
		base: {
			application: {
				font: {
					family: '"DM Sans", sans-serif',
					size: '13px',
					weight: '400',
				},
			},
			button: {
				cursor: 'pointer',
				lineHeight: '24px',
				border: {
					width: '0',
				},
				background: {
					_value: 'none',
				},
				font: {
					weight: '400',
					family: '"DM Sans", sans-serif',
					size: '13px',
				},
				padding: {
					_value: '4px',
				},
				color: 'inherit',
			},
			buttonFlat: {
				box: { sizing: 'inherit' },
				font: {
					weight: '400',
					size: '13px',
					family: '"DM Sans", sans-serif',
				},
				display: 'flex',
				flex: { shrink: '0' },
				justifyContent: 'center',
				align: { items: 'center' },
				cursor: 'pointer',
				border: {
					width: '0px',
					style: 'initial',
					color: 'initial',
				},
				background: {
					_value: 'none',
				},
				margin: {
					_value: '0px auto',
				},
				padding: {
					_value: '5px 10px',
				},
				overrides: [
					{
						selector: 'img[data-selector="base-icon"]',
						style: {
							margin: {
								right: '4px',
							},
						},
					}]
				,
			},
			buttonRaised: {
				box: {
					sizing: 'inherit',
					shadow: 'rgba(0, 33, 121, 0.05) 0px 6px 12px, rgba(0, 20, 74, 0.07) 0px 0px 4px',
				},
				font: {
					weight: '400',
					size: '11px',
				},
				display: 'flex',
				flex: {
					shrink: '0',
				},
				justifyContent: 'center',
				align: {
					items: 'center',
				},
				cursor: 'pointer',
				lineHeight: '24px',
				border: {
					width: '0px',
					style: 'initial',
					color: 'initial',
				},
				color: 'rgb(255, 255, 255)',
				position: 'relative',
				width: 'auto',
				background: {
					color: 'rgb(26, 128, 255)',
				},
				margin: {
					_value: '20px auto',
				},
				padding: {
					_value: '10px 30px',
				},
				selectors: {
					':disabled': {
						background: {
							color: '#939799',
						},
						cursor: 'no-drop',
					},
				},
			},
			iconInput: {
				children: {
					wrapper: {
						position: 'relative',
						width: '340px',
						max: {
							width: '100%',
						},
						margin: {
							_value: '10px auto',
						},
					},
					inputComponent: {
						box: {
							sizing: 'border-box',
						},
						width: '100%',
						height: '40px',
						background: {
							color: '#f3f4f8',
						},
						outline: {
							width: '0',
							style: 'none',
						},
						font: {
							size: '13px',
							weight: '400',
						},
						lineHeight: '1',
						padding: {
							_value: '0 3em',
						},
						selectors: [
							{
								':focus': {
									border: {
										width: '2px',
										style: 'solid',
										color: '#1a80ff',
									},
								},
								'::placeholder': {
									color: '#a9a9a9',
								},
							},
						],
					},
					standardWrapper: {
						overrides: [
							{
								selector: 'input',
								style: {
									border: {
										width: '2px',
										style: 'solid',
										color: 'transparent',
										radius: '1px',
									},
									selectors: {
										':focus': {
											border: {
												width: '2px',
												style: 'solid',
												color: '#1a80ff',
											},
										},
									},
								},
							},
						],
					},
					errorWrapper: {
						overrides: [
							{
								selector: 'input',
								style: {
									border: {
										width: '2px',
										style: 'solid',
										color: '#ff7449',
										radius: '1px',
									},
									selectors: {
										':focus': {
											border: {
												width: '2px',
												style: 'solid',
												color: '#ff7449',
											},
										},
									},
								},
							},
						],
					},
					iconWrapper: {
						position: 'absolute',
						display: 'flex',
						top: '50%',
						transform: 'translateY(-50%)',
						left: '0.75em',
					},
					iconComponent: {
						fill: 'none',
					},
				},
			},
			input: {
				box: {
					sizing: 'border-box',
				},
				height: '40px',
				background: {
					color: '#f3f4f8',
				},
				outline: {
					width: '0',
					style: 'none',
				},
				font: {
					size: '13px',
					weight: '400',
				},
				lineHeight: '1',
				padding: {
					_value: '0 4px',
				},
				selectors: [
					{
						':focus': {
							border: {
								width: '2px',
								style: 'solid',
								color: '#1a80ff',
							},
						},
						'::placeholder': {
							color: '#a9a9a9',
						},
					},
				],
			},
			dropdown:
			{
				children: {
					header: {
						border: {
							bottom: {
								width: '2px',
								style: 'solid',
								color: '#0075BE',
							},
						},
						position: 'relative',
						display: 'inline-block',
						lineHeight: '37px',
						padding: {
							left: '5px',
						},
						font: {
							weight: 'bold',
						},
					},
					headerTitle: {
						float: 'left',
					},
					headerIcon: {
						float: 'right',
						position: 'relative',
						top: '10px',
					},
					listItems: {
						position: 'absolute',
						background: {
							color: '#FFF',
						},
						zIndex: '1000',
						listStyle: {
							type: 'none',
						},
						display: 'inline-block',
						width: '25%',
						margin: {
							_value: '0',
						},
						padding: {
							_value: '0',
						},
					},
					listItem: {
						padding: {
							bottom: '5px',
							top: '5px',
							left: '5px',
						},
						selectors: {
							':hover': {
								background: {
									color: '#0075BE',
								},
								color: '#FFF',
							},
						},
						border: {
							bottom: {
								_value: '1px solid #D9DCE1',
							},
						},
					},
					clickLayer: {
						zIndex: '999',
						position: 'absolute',
						top: '0',
						bottom: '0',
						left: '0',
						right: '0',
						background: {
							color: 'transparent',
						},
					},
				},
			},
			heading: {
				children: {
					h1: {
						color: '#d6d9e1',
						font: {
							size: '15px',
							weight: 'bold',
						},
						max: {
							width: '65%',
						},
						padding: {
							right: '0px',
						},
						text: {
							align: 'center',
						},
						margin: {
							_value: '0px auto 10px',
						},
					},
				},
			},
			imagePicker: {
				children: {
					wrapper: {
						position: 'relative',
						margin: {
							top: '15px',
							left: 'auto',
							right: 'auto',
						},
						width: '200px',
						height: '160px',
						flex: {
							shrink: '0',
						},
						box: {
							sizing: 'content-box',
						},
						border: {
							_value: '1px solid #d6d9e1',
						},
						background: {
							color: '#f3f4f8',
						},
					},
					coveringButton: {
						cursor: 'pointer',
						position: 'absolute',
						width: '100%',
						height: 'inherit',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						display: 'flex',
						justifyContent: 'flex-start',
						padding: {
							_value: '0px',
						},
					},
					buttons: {
						position: 'relative',
						flex: {
							grow: '1',
						},
					},
					toggleButton: {
						cursor: 'pointer',
						position: 'relative',
						display: 'inline-block',
						justifyContent: 'center',
						align: {
							items: 'center',
						},
						width: '100%',
						top: '50%',
						transform: 'translateY(-50%)',
						border: {
							width: '0px',
							style: 'initial',
							color: 'initial',
							image: {
								_value: 'initial',
							},
						},
						background: {
							_value: 'none',
						},
						padding: {
							_value: '0',
						},
						margin: {
							_value: '0',
						},
					},
					refreshButton: {
						cursor: 'pointer',
						display: 'inline-block',
						justifyContent: 'center',
						align: {
							items: 'center',
						},
						width: '100%',
						border: {
							width: '0px',
							style: 'initial',
							color: 'initial',
							image: {
								_value: 'initial',
							},
						},
						background: {
							_value: 'none',
						},
						padding: {
							_value: '0',
						},
						margin: {
							_value: '0',
							top: '20px',
						},
					},
					selectedImage: {
						height: 'inherit',
						position: 'relative',
						border: {
							radius: '0px',
						},
					},
					pickerOverlay: {
						position: 'absolute',
						top: '-1px',
						left: '-1px',
						width: '360px',
						height: '240px',
						box: {
							shadow: 'rgba(0, 33, 121, 0.05) 0px 6px 12px, rgba(0, 20, 74, 0.07) 0px 0px 4px',
						},
						zIndex: '1',
						display: 'flex',
						background: {
							color: '#f3f4f8',
						},
						border: {
							_value: '1px solid #d6d9e1',
							image: {
								_value: 'initial',
							},
						},
					},
					thumbnails: {
						width: '320px',
						position: 'relative',
						height: '100%',
						overflow: {
							y: 'auto',
						},
						display: 'flex',
						flex: {
							wrap: 'wrap',
						},
						justifyContent: 'flex-start',
						box: {
							sizing: 'border-box',
						},
						background: {
							color: 'white',
						},
					},
					thumbnail: {
						cursor: 'pointer',
						width: 'calc(100% / 3)',
						height: '107px',
						padding: {
							_value: '0px',
						},
					},
					thumbnailImage: {
						width: '100%',
						position: 'relative',
						border: {
							radius: '0px',
						},
					},
				},
			},
		},
		message: {
			base: {
				text: {
					align: 'center',
				},
				font: {
					family: '"DM Sans", sans-serif',
					weight: 'normal',
					size: '13px',
				},
				max: {
					width: ' 65%',
				},
				color: '#33da94',
				margin: {
					_value: '0px auto',
				},
				padding: {
					right: '0px',
				},
			},
			errorMessage: {
				color: 'rgb(255, 116, 73)',
			},
			infoMessage: {
				color: '#D6D9E1',
			},
			successMessage: {
				color: '#33da94',
			},
		},
	},
};