import React, { Component } from 'react';
import {
	IMfaSetupProps,
	IMfaSetupState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import { MfaStep } from './stores/MfaSetupStore';
import MfaSetupPush from '../MfaSetupPush';
import MfaSetupBackup from '../MfaSetupBackup';

@inject('appStore', 'mfaSetupStore')
@observer
export class MfaSetup extends Component<IMfaSetupProps, IMfaSetupState> {
	static configurationKey: 'mfaSetup' = 'mfaSetup';
	static styleKey: 'mfaSetup' = 'mfaSetup';
	constructor(props: IMfaSetupProps) {
		super(props);
	}
	render() {
		const { className, mfaSetupStore } = this.props;

		if (!mfaSetupStore) {
			return null;
		}

		const { step } = mfaSetupStore;
		return (
			<Wrapper className={ className }>
				{ step === MfaStep.pushSetup && <MfaSetupPush /> }
				{ step === MfaStep.backupSetup && <MfaSetupBackup /> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(MfaSetup, 'mfaSetup');

