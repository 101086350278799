import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IMfaSetupConfiguration } from '../models';
import { config } from '../../App/models/config';

export enum MfaMethods {
	push = 'PUSH',
	call = 'CALL',
	text = 'TEXT',
}

export enum MfaStep {
	pushSetup = 'PUSH_SETUP',
	backupSetup = 'BACKUP_SETUP',
}

export class MfaSetupStore extends StoreBase {
	static componentKey: 'mfaSetup' = 'mfaSetup';
	@observable step: MfaStep = MfaStep.pushSetup;
	@computed
	get codeValidation() {
		const { setPasswordStore } = this.storeContext;
		return setPasswordStore.codeValidation;
	}

	@computed
	get enabled2FAMethods(): string[] {
		return (this.codeValidation && this.codeValidation.enabled2FAMethods) || [];
	}

	@computed
	get isPushEnabled(): boolean {
		return this.enabled2FAMethods.includes(MfaMethods.push);
	}

	@computed
	get isCallEnabled(): boolean {
		return this.enabled2FAMethods.includes(MfaMethods.call);
	}

	@computed
	get isTextEnabled(): boolean {
		return this.enabled2FAMethods.includes(MfaMethods.text);
	}

	@computed
	get configuration(): IMfaSetupConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(MfaSetupStore.componentKey);
		}
	}

	@action
	setStep = (step: MfaStep) => {
		if (step === MfaStep.pushSetup && this.isPushEnabled) {
			this.step = step;
		}
		else if (step === MfaStep.backupSetup && (this.isCallEnabled || this.isTextEnabled)) {
			this.step = step;
		}
		else {
			const { appStore } = this.storeContext;
			appStore.setStep(config.loginSteps.USER_SUCCESS);
		}
	}

	@action
	async initialize(): Promise<void> {
		if (this.isPushEnabled) {
			this.setStep(MfaStep.pushSetup);
			const { mfaSetupPushStore } = this.storeContext;
			mfaSetupPushStore.initialize();
		}
		else if (this.isCallEnabled || this.isTextEnabled) {
			this.setStep(MfaStep.backupSetup);
			const { mfaSetupBackupStore } = this.storeContext;
			mfaSetupBackupStore.initialize();
		}
		else {
			const { appStore } = this.storeContext;
			appStore.setStep(config.loginSteps.USER_SUCCESS);
		}
	}


}