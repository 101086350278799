import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IForgotUsernameConfiguration } from '../models';
import { config } from '../../App/models/config';
import { utils } from '@kurtosys/ksys-app-template';

export class ForgotUsernameStore extends StoreBase {
	static componentKey: 'forgotUsername' = 'forgotUsername';
	@observable public email = '';

	@computed
	get configuration(): IForgotUsernameConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(ForgotUsernameStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@action
	public backToUserLogin = () => {
		const { messageStore, userLoginStore, appStore } = this.storeContext;
		messageStore.clearError();
		userLoginStore.clear();
		appStore.setStep(config.loginSteps.USER_LOGIN);
	}

	@computed
	get backButtonText(): string {
		return (this.configuration && this.configuration.backButtonText) || 'Login';
	}

	@computed
	get forgotUsernameButtonText(): string {
		return (this.configuration && this.configuration.forgotUsernameButtonText) || 'Email';
	}

	@computed
	get placeholderText(): string {
		return (this.configuration && this.configuration.placeholderText) || 'Request Forgot Username';
	}

	@computed
	get successMessage(): string {
		return (this.configuration && this.configuration.successMessage) ||
			'If your user exists, you should receive an email with more information, please follow the instructions listed there';
	}

	@computed
	get invalidMessage(): string {
		return (this.configuration && this.configuration.invalidMessage) ||
			'You have not provided enough information to complete this request';
	}

	@computed
	get errorMessage(): string {
		return (this.configuration && this.configuration.errorMessage) ||
			'An error occurred while processing this request. Please contact your administrator';
	}

	@action
	public updateEmail = (value: string) => {
		const { messageStore } = this.storeContext;
		this.email = value;
		messageStore.clearAll();
	}

	public onSubmitRequestUsernameRequest = async () => {
		const { messageStore } = this.storeContext;
		messageStore.clearAll();
		if (utils.typeChecks.isNullOrEmpty(this.email)) {
			messageStore.setErrorText('Email is required.');
			return false;
		}
		const { appStore, kurtosysApiStore } = this.storeContext;
		const loadingKey = 'ForgotUsernameStore.onSubmitRequestUsernameRequest';
		appStore.startLoading(loadingKey);
		const overrides = {
			body: {
				email: this.email,
			},
		};
		const response = await kurtosysApiStore.usernameRequest.callApi(overrides);
		if (response.status === 200) {
			messageStore.setInfoText(this.successMessage);

		}
		else if (response.status === 400) {
			messageStore.setInfoText(this.invalidMessage);
		}
		else {
			messageStore.setErrorText(this.errorMessage);
		}
		appStore.stopLoading(loadingKey);
	}
}