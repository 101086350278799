import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISetupAssurancesConfiguration, ISetupAssuranceInstructionText, SetupAssuranceSource } from '../models';
import { IImagePickerImage } from '@kurtosys/ksys-app-components/dist/components/base/ImagePicker/models/IImagePickerImage';
import { IImagePickerProps } from '@kurtosys/ksys-app-components/dist/components/base/ImagePicker/models';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { TAssuranceImagesRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TAssuranceImagesRequestBody';
import { IAssuranceImage } from '@kurtosys/ksys-api-client/dist/models/requests/auth/IAssuranceImage';
import { config } from '../../App/models/config';

const MAX_MESSAGE_LENGTH = 128;
export class SetupAssurancesStore extends StoreBase {
	static componentKey: 'setupAssurances' = 'setupAssurances';
	@observable.ref images: IImagePickerImage[] = [];
	@observable.ref selectedImage: IImagePickerImage | undefined;
	@observable message: string = '';
	@observable source: SetupAssuranceSource = SetupAssuranceSource.login;
	@computed
	get configuration(): ISetupAssurancesConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SetupAssurancesStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		this.getAssuranceImages();
	}

	get token(): string | undefined {
		const { userLoginStore, setPasswordStore } = this.storeContext;
		return userLoginStore.token || setPasswordStore.resetToken;
	}

	@computed
	get imageLimit(): number {
		if (this.configuration && typeof this.configuration.imageLimit === 'number') {
			return this.configuration.imageLimit;
		}
		return 9;
	}

	@action
	getAssuranceImages = async () => {
		const { kurtosysApiStore, userLoginStore } = this.storeContext;
		let rawImages: IAssuranceImage[] = [];
		if (this.token) {
			const overrideOptions: Partial<IRequestOptions<TAssuranceImagesRequestBody>> = {
				body: {
					passwordRequestId: this.token,
					limit: this.imageLimit,
				},
				disableCachableRequests: true,
			};
			const assuranceImagesResponse = await kurtosysApiStore.assuranceImages.execute(overrideOptions);
			if (assuranceImagesResponse && Array.isArray(assuranceImagesResponse.images)) {
				rawImages = assuranceImagesResponse.images;
			}
		}
		const BASE64_PNG_PREFIX = 'data:image/png;base64,';
		const images = rawImages.map((rawImage) => {
			let src = rawImage.image;
			if (!src.startsWith(BASE64_PNG_PREFIX)) {
				src = BASE64_PNG_PREFIX + src;
			}
			return {
				src,
				id: rawImage.imageId,
			} as IImagePickerImage;
		});
		if (!this.selectedImage && images.length > 0) {
			this.selectedImage = images[0];
		}
		this.images = images;
	}

	@action
	onImageSelect = (image: IImagePickerImage) => {
		if (image) {
			this.selectedImage = image;
		}
	}

	@action
	onImagePickerRefresh = () => {
		this.getAssuranceImages();
	}

	@action
	updateMessage = (message: string) => {
		if (message.length <= MAX_MESSAGE_LENGTH) {
			this.message = message;
		}
	}

	@computed
	get messageRemainingCharactersCount(): number {
		return MAX_MESSAGE_LENGTH - (this.message || '').length;
	}

	@computed
	get messageRemainingCharactersText(): string {
		return (this.configuration && this.configuration.messageRemainingCharactersText) || '{count} characters remaining';
	}

	@computed
	get assuranceStore() {
		const { assuranceStore } = this.storeContext;
		return assuranceStore;
	}

	@computed
	get heading() {
		return (this.configuration && this.configuration.heading) || 'Setup Assurances';
	}

	@computed
	get messagePlaceholderText() {
		return (this.configuration && this.configuration.messagePlaceholderText) || 'Assurance message';
	}

	@computed
	get messageValid() {
		return this.message && this.message.length > 0 && this.message.length <= MAX_MESSAGE_LENGTH;
	}

	@computed
	get instructionText(): string | undefined {
		const { assuranceStore, setPasswordStore } = this.storeContext;
		const defaultInstructionTexts: ISetupAssuranceInstructionText = {
			messageAndImage: 'Please provide a personal assurance message and image to be displayed when you log in',
			messageOnly: 'Please provide a personal assurance message to be displayed when you log in',
			imageOnly: 'Please provide a personal assurance image to be displayed when you log in',
		};
		const instructionTextConfig = Object.assign(defaultInstructionTexts, (this.configuration && this.configuration.instructionText) || {});
		const imageRequired = assuranceStore.imageRequired || setPasswordStore.imageRequired;
		const messageRequired = assuranceStore.messageRequired || setPasswordStore.messageRequired;
		if (imageRequired && messageRequired) {
			return instructionTextConfig.messageAndImage;
		}
		if (messageRequired) {
			return instructionTextConfig.messageOnly;
		}
		if (imageRequired) {
			return instructionTextConfig.imageOnly;
		}
		return undefined;
	}

	@computed
	get buttonText(): string {
		return (this.configuration && this.configuration.buttonText) || 'Finish';
	}

	@computed
	get isValid(): boolean {
		const { imageRequired, messageRequired } = this.assuranceStore;
		return !!((!imageRequired || this.selectedImage) && (!messageRequired || this.messageValid));
	}

	@action
	handleUpdateAssurances = () => {
		const { appStore } = this.storeContext;
		if (appStore.hasStepInHistory(config.loginSteps.SET_PASSWORD)) {
			const { setPasswordStore } = this.storeContext;
			setPasswordStore.updatePassword(true);
		}
		else {
			this.updateAssurances();
		}
	}

	@action
	updateAssurances = async () => {
		if (this.isValid && this.selectedImage) {
			const { kurtosysApiStore, appStore, messageStore } = this.storeContext;
			const loadingKey = 'SetupAssurancesStore.updateAssurances';
			appStore.startLoading(loadingKey);
			const response = await kurtosysApiStore.assuranceUpdate.callApi({
				body: {
					imageId: this.selectedImage.id,
					message: this.message,
				},
			});
			// TODO: ADD LOGIC FOR MFA HERE
			if (response.ok) {
				appStore.redirectTo(appStore.redirectURL);
			}
			else {
				messageStore.setErrorText(this.updateFailedText);
			}
			appStore.stopLoading(loadingKey);

		}
	}

	@computed
	get updateFailedText() {
		return (this.configuration && this.configuration.updateFailedText) || 'Assurance update has failed';
	}

	@computed
	get imagePickerProps(): IImagePickerProps {
		const props: IImagePickerProps = {
			hide: !this.assuranceStore.imageRequired && !this.storeContext.setPasswordStore.imageRequired,
			onSelect: this.onImageSelect,
			onRefresh: this.onImagePickerRefresh,
			images: this.images,
			selected: this.selectedImage,
		};
		return props;
	}
}