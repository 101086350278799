import React, { Component } from 'react';
import {
	IPhoneNumberProps,
	IPhoneNumberState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Input from '@kurtosys/ksys-app-components/dist/components/base/Input';
import Dropdown from '@kurtosys/ksys-app-components/dist/components/base/Dropdown';

@inject('appStore', 'phoneNumberStore')
@observer
export class PhoneNumber extends Component<IPhoneNumberProps, IPhoneNumberState> {
	static configurationKey: 'phoneNumber' = 'phoneNumber';
	static styleKey: 'phoneNumber' = 'phoneNumber';
	constructor(props: IPhoneNumberProps) {
		super(props);
	}
	render() {
		const { className, phoneNumberStore, inputProps, dropdownProps = { items: [] } } = this.props;

		if (!phoneNumberStore) {
			return null;
		}
		return (
			<Wrapper className={ className }>
				<Dropdown { ...dropdownProps } />
				<Input { ...inputProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(PhoneNumber, 'phoneNumber');

